<template>
  <b-card>

    <header-table title="قاعات المنارة" @create="onAdd" />

    <data-table ref="estatesTable" :pagination="false" :fields="fields" ep="/halls">
      <template #cell(hall_type)="row" >
        <div>
          {{row.item.hall_type=='T' ? 'تدريبة': row.item.hall_type=='W' ?'مساحة عمل':  row.item.hall_type=='M' ?'مكتب':''}}
        </div>
      </template>
      <template #cell(floor)="row" >
        <div>
          {{row.item.floor ? 'الطابق الأول':'الطابق الأرضي'}}
        </div>
      </template>
      <template #cell(actions)="row">
        <actions-table
          @delete="onDelete(row.item)"
          @update="onUpdateClicked(row.item)"
        />
      </template>
    </data-table>
    <form-modal
      ref="estateModalHalls"
      :formSchema="estatesFormSchema"
      title="إضافة"
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
    >
    </form-modal>
  </b-card>
</template>
    <script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  VBToggle,
  VBTooltip,
  BCollapse,
  BImg,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import DataTable from "@/components/data-table/index";
import FormInput from "@/components/form-input/index";
import Ripple from "vue-ripple-directive";
import FormModal from "@/components/FormModal.vue";
import FormFile from "@/components/form-file/index.vue";
import { mapActions, mapGetters } from "vuex";
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import { typesHalls,floors,yards } from "@/utils/items-enam";
import HeaderTable from "@/components/data-table/components/header-table.vue";
import ActionsTable from "@/components/data-table/components/actions-table.vue";
export default {
  components: {
    BCard,
    DataTable,
    BButton,
    FormInput,
    BRow,
    BCol,
    FormModal,
    FormFile,
    BCollapse,
    VBTooltip,
    VBToggle,
    BImg,
    BCarousel,
    BCarouselSlide,
    BMediaAside,
    BMediaBody,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    HeaderTable,
    ActionsTable
},
  directives: {
    Ripple,
  },
  data: () => {
    return {
      imageSlide: [],
      staticImages: [],
      fields: [
        { key: "code", label: "رقم القاعة", sortable: true },
        { key: "hall_name", label: "اسم القاعة"},
        { key: "yard", label: "الباحة"},
        { key: "floor", label: "الطابق" },
        { key: "hall_type", label: "نوع القاعة" },
        { key: "capacity", label: "سعة القاعة" },
        { key: "ip_camera", label: "ip الكاميرا" },
        { key: "actions", label: "" },
      ],
      activModel:false,
      estatesFormSchema: {
        code: { 
          component: "FormInput",
          key: "code",
          attrs: {
            id: "code",
            label: "رقم القاعة",
            rules: "required",
          },
        },
        hall_name: { 
          component: "FormInput",
          key: "hall_name",
          attrs: {
            id: "hall_name",
            label: "اسم القاعة",
            rules: "required",
          },
        },
        capacity: {
          component: "FormMask",
          key: "capacity",
          attrs: {
            label: "سعة القاعة",
            options: "number",
            rules: "required",
            dir: "ltr",
            placeholder: "",
          },
        },
        floor: {
          component: "FormSelect",
          key: "floor",
          attrs: {
            id: "floor",
            label: "الطابق",
            rules: "required",
            reduce: (el) => el.value,
            options: floors,
          },
        },
        yard: {
          component: "FormSelect",
          key: "yard",
          attrs: {
            id: "yard",
            label: "الباحة",
            rules: "required",
            reduce: (el) => el.value,
            options: yards,
          },
        },
        hall_type: {
          component: "FormSelect",
          key: "hall_type",
          attrs: {
            id: "hall_type",
            label: "نوع القاعة",
            rules: "required",
            reduce: (el) => el.value,
            options: typesHalls,
          },
        },
        ip_camera:{
          component: "FormInput",
          key: "ip_camera",
          attrs: {
            id:'ip_camera',
            label: "ip الكميرا",
            // options: "number",
            rules: "required",
            dir: "rtl",
            placeholder: "255.255.255.255",
          },
        }
      },
      utils: {
        status: "",
      },
      filter: "",
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    ...mapActions("settings/manageHalls", [
      "updateHalls",
      "createHalls",
      "deleteHalls",
    ]),

    onAdd() {
      this.$refs.estateModalHalls.init({});
      this.activModel = true;
    },

    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        code: form.code,
        capacity: form.capacity,
        floor: form.floor,
        hall_type: form.hall_type,
        ip_camera: form.ip_camera,
      };
      this.$refs.estateModalHalls.init(toUpdate);
      this.activModel = true;
    },

    onDelete(item) {
      // console.log(id)
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف النشاط", {
          title: "تأكيد حذف النشاط",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteHalls({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },

    onModalConfirmed(form) {
       if (form.id) {
        this.updateHalls({ id: form.id, data: form }).then((res) => {
          console.log(res)
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.createHalls({ data: form  }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },
  },

  computed: {
    ...mapGetters("settings/manageHalls", ["loading"]),
  },
};
</script>
  <style lang="scss" >
.top-m {
  padding-top: 7px;
}
</style>